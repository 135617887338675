export function toast(arg) {
    let message = '';
    let timeout = 5000;
    let type = 'default';
    if (typeof arg === 'string') {
        message = arg;
    } else {
        message = arg.message;
        if (arg.timeout) {
            timeout = arg.timeout;
        }
        if (arg.type) {
            type = arg.type;
        }
    }
    let toastWrapper = document.querySelector('.toast-wrapper');
    if (!toastWrapper) {
        toastWrapper = document.createElement('div');
        toastWrapper.classList.add('toast-wrapper');
        toastWrapper.style.position = 'fixed';
        toastWrapper.style.top = '0';
        toastWrapper.style.right = '0';
        toastWrapper.style.zIndex = '1000';
        toastWrapper.style.width = '300px';
        toastWrapper.style.height = '100%';
        toastWrapper.style.display = 'flex';
        document.body.appendChild(toastWrapper);
    }
    toastWrapper.style.display = '';
    // 创建 toast 元素
    const toastEl = document.createElement('div');
    toastEl.classList.add('toast');
    toastEl.style.marginBottom = '30px';
    toastEl.style.padding = '10px';
    toastEl.style.boxShadow = 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px';
    toastEl.style.transform = 'translateX(100%)';
    toastEl.style.transition = 'transform 0.3s ease';
    toastEl.style.position = 'absolute';
    toastEl.style.right = '0';
    toastEl.style.left = '0';
    toastEl.style.top = 70 + toastWrapper.children.length * 50 + 'px';
    if (type === 'default') {
        toastEl.style.background = '#e1dcd8';
    } else if (type === 'success') {
        toastEl.style.background = '#d4edda';
    } else if (type === 'error') {
        toastEl.style.background = '#f8d7da';
    }
    // 创建 toast 内容
    const contentEl = document.createElement('div');
    contentEl.style.whiteSpace = 'pre';
    contentEl.style.fontSize = '14px';
    contentEl.style.lineHeight = '1.5';
    contentEl.classList.add('content');
    contentEl.textContent = message;
    toastEl.appendChild(contentEl);
    toastWrapper.appendChild(toastEl);
    // 显示 toast
    toastEl.clientHeight;
    toastEl.style.transform = 'translateX(0)';
    setTimeout(() => {
        toastEl.style.transform = 'translateX(100%)';
        setTimeout(() => {
            toastEl.remove();
            if (toastWrapper.children.length === 0) {
                toastWrapper.style.display = 'none';
            }
        }, 300);
    }, timeout);
}
export function errorToast(message) {
    toast({ message, type: 'error' });
}
export function successToast(message) {
    toast({ message, type: 'success' });
}
